import React from "react"
import Loadable from 'react-loadable'
import styled from "styled-components"
import { dark, light, highlight } from "../components/styles"

import Seo from "../components/seo"

const CVPageMain = styled.div`
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 22vh 420vh;
  @media (max-width: 768px){
    margin: 20px;
    grid-template-columns: auto;
    grid-template-rows: 50vh auto;
    grid-row-gap: 40px;
  }
  align-items: center;
  justify-items: center;
`
const CVTextContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-column-start: 2;
  display: grid;
  grid-template-columns: 100%;
  color: ${dark};
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
`
const CVTitle = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  align-self: center;
`
const CVText = styled.div`
  grid-row-start: 2;
  font-weight: bolder;
  font-size: 1.1rem;
  grid-column: 1 / span 2;
`

const CVLinkContainer = styled.div`
  display: grid;
  grid-template-columns: 180px 65px 65px;
  justify-items: start;
`

const CVLink1 = styled.a`
  text-decoration: none;
  grid-row-start: 1;
  grid-column-start: 1;
  color: ${highlight};
`

const CVLink2 = styled.a`
  text-decoration: none;
  grid-row-start: 1;
  grid-column-start: 2;
  color: ${highlight};
`

const CVLink3 = styled.a`
  text-decoration: none;
  grid-row-start: 1;
  grid-column-start: 3;
  color: ${highlight};
`

const EditorContainer = styled.div`
  align-self: start;
  justify-self: start;
  width: 100%;
  height: 100%;
  grid-column-start: 2;
  grid-row-start: 2;
  border-radius: 20px;
  box-shadow:
          9px 9px 13px 0 rgba(0, 0, 0, 0.25),
            -5px -5px 9px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow:
            9px 9px 13px 0 rgba(0, 0, 0, 1),
              -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
  }
`

const LoadableEditor = Loadable({
  loader: () => import('../components/elements/editor'),
  loading() {
    return <div>Loading...</div>
  }
});

const ResumePage: React.FC = () => {
  return <>
    <Seo title="resume"/>
    <CVPageMain>
      <CVTextContainer>
        <CVTitle>
          <h1>CV - Matthew R. Fisher</h1>
        </CVTitle>
        <CVText>
          <p>Please check out resume or download a version of it from the links below!</p>
          <CVLinkContainer>
            <CVLink1 href={"https://www.mfisher.dev/documents/CVMatthewR.Fisher.pdf"}>Standard Resume</CVLink1>
            <CVLink2 href={"https://www.mfisher.dev/documents/CVMatthewFisher.yml"}>YAML</CVLink2>
            <CVLink3 href={"https://www.mfisher.dev/documents/CVMatthewFisher.json"}>JSON</CVLink3>
          </CVLinkContainer>
        </CVText>
      </CVTextContainer>
      <EditorContainer>
        <LoadableEditor name={"cv"} val={
`# my resume
me:
  name:
    first: Matthew
    last: Fisher
  contact:
    website: mfisher.dev
    email: matt@mfisher.dev
    github: github.com/Karstagg
  summary:
    - mobile developer
    - software engineer
    - linguist
    - foodie
    - travel enthusiast
  technical_skills:
    languages:
      - JavaScript
      - TypeScript
      - CSS
      - HTML
      - Swift
    libraries_and_frameworks:
      - React Native
      - React
      - Redux
      - Relay
      - Gatsby.js
      - Fastlane
    data:
      - GraphQL
      - PostgreSQL
      - MySQL
      - REST
    cloud_and_ci:
      - Google Play Store Console
      - Apple App Store Connect
      - Google Firebase
      - CircleCi
      - Bitrise
      - AWS
      - Google Cloud
      - sentry.io
      - Netlify
work_experience:
  software_development:
    companies:
      - company_name: Scoville
        title: Senior Software Engineer
        period: 09/2020 - Current
        overview: Worked with an amazingly talented international team to develop React Native mobile applications for the Japanese market
        projects:
          - name: CircleApp
            description: React Native social networking application
            role: React Native developer / Devops
            team: 6 members, based in Japan, Germany, and the U.S.
            technologies: React Native, Android, iOS, Relay, GraphQL, PostgreSQL, Elixer, Pheonix, Hydra, CircleCi, Fastlane, AWS
            responsibilities: React Native development, app releases and beta testing, frontend CI
      - company_name: BeatFit Inc.
        title: Software Engineer
        period: 07/2019 - 04/2020
        overview: Worked on an award winning mobile fitness application using React Native and Swift
        projects:
          - name: BeatFit
            description: React Native fitness and entertainment application for the Japanese Market
            role: React Native and Firebase developer
            team: 6 members, based in Japan
            technologies: React Native, Android, iOS, Firebase, Redux, RxJS, Redux-Observable, Ruby on Rails, PostgreSQL, Google Cloud
            responsibilities: React Native development, Firebase admin, Testflight admin
          - name: BeatFit Watch
            description: Apple Watch extension for the BeatFit mobile application
            role: Lead developer
            team: 3 members, based in Japan
            technologies: Swift, WatchOS, React Native
            responsibilities: Development of a WatchOS extension and integration with the existing React Native application
          - name: BeatFit Demo
            description: A web application built for members of a partner Gym
            role: React / Gatsby developer
            team: 2 members, based in Japan
            technologies: Gatsby.js, React, CSS, GraphQL
            responsibilities: React / Gatsby development
      - company_name: Planetway Japan
        title: Software Engineer
        period: 09/2018 - 05/2019
        overview: Built web and mobile application based on and about open source and proprietary internet security and privacy technologies
        projects:
          - name: Planetway Corporate Website
            description: Corporate Website for Planetway Japan
            role: React / Gatsby developer
            team: 3 members, based in Japan, the U.S. and Estonia
            technologies: Gatsby, React, CSS, CircleCI, Contentful
            responsibilities: React / Gatsby development
          - name: PlanetID
            description: React Native Universal ID and 2FA application
            role: React Native developer
            team: 10 members, based in Japan and Estonia
            technologies: React Native, Android, iOS, Python, Flask, PostgreSQL
            responsibilities: React Native development, Redux implementation
          - name: Planetway Console Documentation
            description: Information / ducumentation site for Planetway's products and services
            team: 2 members, based in Japan
            technology: Gatsby, React, CSS, Markdown
            responsibilities: Implementation of designs in Gatsby
      - company_name: Lyra Development / Aquila
        title: Lead Developer
        period: 08/2017 - 09/2018
        overview: Developed in-house software solutions for our parent company (Aquila), developed mobile applications for a variety of industries
        projects:
          - name: Sentry RMS Ticketing System
            description: A trouble ticket management tool for radiation safety equipment
            role: fullstack engineer / project management / Devops
            team: 3 members, based in the U.S.
            technologies: Angular, PHP, CSS, MySQL
            responsibilities: Creating Angular components and PHP REST apis, server maintainence and deployments, project management, customer support
          - name: TastySpot
            description: A two part React Native application for customers to locate and order from food trucks, and for vendors to sell items
            role: React Native and Firebase developer, Project Manager
            team: 3 members, based in the U.S.
            technologies: React Native, Firebase, Redux, Android, iOS
            responsibilities: Firebase admin, React Native development, project management
          - name: AllDetect
            description: React Native application built to interface with state of the art radiation detection equipment
            role: React Native developer / project management / customer outreach
            team: 3 members, based in the U.S.
            technologies: React Native, Android, SQLite
            responsibilities: React Native development, project management, customer outreach, client contact
      - company_name: Sabio Systems
        title: contract front end developer (half time)
        period: 06/2017 - 08/2017
        overview: Developed custom web applications for clients
        projects:
          - name: Management Suit
            description: A custom management tool for a client in the construction industry
            role: Front End developer
            team: 2, based in the U.S.
            technologies: Vue, Ruby on Rails, PostgreSQL
            responsibilities: Vue Development
  other:
    companies:
      - company_name: CNM STEMulus Center
        title: Technical Coach
        period: 02/2017 - 10/2017
        job_description:
        overview: Assisted special needs students with coursework for the Deep Dive Fullstack programming bootcamp
        role: Teaching assistant and content developer
        team: 4 team members, 15 students, based in the U.S.
        technologies: Angular, JavaScript, TypeScript, PHP, HTML, CSS, Ubuntu
        responsibilities: Assisting special needs students and their teams with projects and course material, creating new video tutorials on contract for the bootcamp
education:
  postgraduate:
    - school_name: Phillips-University Marburg
      departments:
        - FB 10 Fremdsprachliche Philologien
      period: 10/2014 - 07/2016
      degree: M.A. in Linguistics and Web Technology
      areas_of_study:
        - Web development as it pertains to linguistics
        - PHP, HTML, JavaScript, JQuery, CSS, MySQL
        - Semantics
        - Morphology and Syntax
        - Cognitive Linguistics
        - Corpus Linguistics
      thesis: Lingorator - A web application that generates new human languages based on user input and certain linguistic rules
  college:
    - school_name: University of Colorado Boulder
      departments:
        - Linguistics
        - East Asian Languages and Cultures
      period: 08/2011 - 12/2014
      degree: B.A. Double Major in Lingustics and Japanese Language / Culture
      areas_of_study:
        - Semantics
        - Morphology and Syntax
        - Cognitive Linguistics
        - Corpus Linguistics
        - Japanese Language
        - Japanese Literature
    - school_name: Waseda University
      departments:
        - School of International Liberal Studies (SILS)
      period: 09/2013 - 08/2014
      degree: N/A Study Abroad Program
      areas_of_study:
        - Japanese Language
        - Generative Syntax
        - ESL
  other:
    - school_name: CNM DeepDive
      departments:
        - Fullstack bootcamp
      period: 10/2016 - 12/2016
      degree: Certificate of Completion
      areas_of_study:
        - Fullstack web development using Angular and PHP
        - computer science fundamentals
        - linux devops`} height="100%" width="100%"/>
      </EditorContainer>
    </CVPageMain>
  </>
}
export default ResumePage
